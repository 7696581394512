import axios from 'axios'
import router from '@/router'
import store from './index'

let timer;

export default {
  async login(context, payload) {
   // console.log(process.env,process.env.VUE_APP_API)
    const url = '/login-web';
    try {
      const response = await axios.post(url, payload);


      const {data: {token, user,  sysconf,suppliers,numeration}} = response.data;

   //   console.log(token)
      // this.axios.defaults.headers.common.Authorization = `${token_type} ${access_token}`
      axios.defaults.headers.common.Authorization      = `Bearer ${token}`;

      const expiresIn      = 340025 * 1000;
      const expirationDate = new Date(expiresIn)  ;
    //  console.log(expirationDate.getTime())
      sessionStorage.setItem('token', token);
      sessionStorage.setItem('user', JSON.stringify(user));/*
      sessionStorage.setItem('customer', JSON.stringify(customer));
      sessionStorage.setItem('products', JSON.stringify(products));*/
      sessionStorage.setItem('sysconf', JSON.stringify(sysconf));
      sessionStorage.setItem('loggedIn', true);
      sessionStorage.setItem('suppliers', JSON.stringify(suppliers));
      sessionStorage.setItem('numeration', JSON.stringify(numeration));
      sessionStorage.setItem('tokenExpiration', JSON.stringify(expirationDate));


     /* timer = setTimeout(function () {
        context.dispatch('autoLogout')
      }, expiresIn)*/

      context.commit('setToken', {
        token:           token,
        tokenExpiration: expirationDate,
      });

      return true
    } catch (error) {
      axios.defaults.headers.common.Authorization = null
      // this.axios.defaults.headers.common.Authorization = null
      const errorLogin                            = new Error(
          error.message || 'Failed to authenticate. Check your login data.'
      )
    //  console.log(errorLogin.message);
      if (errorLogin.message === 'Request failed with status code 404') {

        context.commit('setMessageLogin', {
          message: "Usuario o Cantraseña invalidos"
        });
      }
      throw errorLogin
    }
  },

  tryLogin(context) {
    const token           = sessionStorage.getItem('token');
    const tokenExpiration = sessionStorage.getItem('tokenExpiration');
    const loggedIn = sessionStorage.getItem('loggedIn');

    const expiresIn = +tokenExpiration - new Date().getTime();



    axios.defaults.headers.common.Authorization = `Bearer ${token}`;



    if (token) {
      context.commit('setToken', {
        token:           token,
        tokenExpiration: tokenExpiration,
      })
    }
  },

  async menuUser(context) {
    const url = `/roles-permisos/menu-active-user`;
    try {
      const response = await axios.get(url);

      sessionStorage.setItem('menu', JSON.stringify(response.data));
    //  console.log(response.data)
      context.commit('setMenu', {
        menu: response.data
      });
    } catch (error) {

      const errorLogin = new Error(
          error.message || 'Failed to authenticate. Check your login data.'
      )
      if (errorLogin.message === 'Request failed with status code 401') {
        this.dispatch('autoLogout');
      }

      throw errorLogin
    }
  },
  async tryMenuUser(context) {

    try {

      let data =sessionStorage.getItem('menu');
    //  console.log(data)
      context.commit('setMenu', {
        menu: JSON.parse(data)
      });
    } catch (error) {

      const errorLogin = new Error(
          error.message || 'Failed to authenticate. Check your login data.'
      )
      if (errorLogin.message === 'Request failed with status code 401') {
        this.dispatch('autoLogout');
      }

      throw errorLogin
    }
  },

 async getLogout(context) {
   sessionStorage.setItem('loggedIn', false);
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('tokenExpiration');
   localStorage.removeItem('data_menus');
   localStorage.removeItem('invoicesLists');
    sessionStorage.removeItem('invoicesLists');
   localStorage.removeItem('reception_lists');
    sessionStorage.removeItem('productLists');
   localStorage.removeItem('productLists');
    sessionStorage.removeItem('menu');
   localStorage.removeItem('userSession');
    sessionStorage.removeItem('receptionLists');
   localStorage.removeItem('receptionLists');
    sessionStorage.removeItem('titles');
   localStorage.removeItem('titles');
    sessionStorage.removeItem('edit_menu');
    sessionStorage.removeItem('data_roles');
   localStorage.removeItem('customerLists');
   localStorage.removeItem('info_sysconf');
    sessionStorage.removeItem('data_users');
    localStorage.removeItem('sysconf');
    sessionStorage.removeItem('sysconf');
    sessionStorage.removeItem('suppliers');
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('reception_Lists');

    clearTimeout(timer);
//console.log(' cerror sesion')
    delete axios.defaults.headers.common.Authorization;
    context.commit('setLogout');

  },

  autoLogout(context) {
    context.dispatch('logout');
    context.commit('setAutoLogout');

  }
}
