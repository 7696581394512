export default {

  DATAROLES(state) {
    return state.dataRoles
  },
  DATAUSERS(state) {
    return state.dataUsers
  },
  PERMISSIONUSER(state) {
    console.log("cambio en linea "+state.permissionUser)
    return state.permissionUser
  },
}