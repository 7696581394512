import axios from "axios";

export default {
  /**
   * Title Menú
   * Código de Roles y permisos
   * @param context
   * @returns {Promise<*>}
   */
  async titlesMenu(context) {
    const url = `/roles-permisos/lists-title-menus`;
    try {
      const response = await axios.get(url);

      localStorage.setItem('titleMenus', JSON.stringify(response.data));

      context.commit('setTitlesMenu', {
        titles: response.data
      });

      return response.data
    } catch (error) {

      const errorLogin = new Error(
        error.message || 'Failed to authenticate. Check your login data.'
      )
      throw errorLogin
    }
  },
  async fetchTitles({ commit }, context) {
    await axios.get('/roles-permisos/lists-title-menus')
      .then((response) => {
        commit('setTitlesMenu', response);
      })
      .catch((error) => {
        console.error(error);
      });
  },
  async registerTitle(context, payload) {
    const url = `/roles-permisos/store-title-menu`;
    try {
      const response = await axios.post(url, payload);


      return response.data
    } catch (error) {

      const errorLogin = new Error(
        error.message || 'Failed to authenticate. Check your login data.'
      )
      throw errorLogin
    }
  },
  async sendUpdateTitle(context, payload) {
    const url = `/roles-permisos/update-title-menu/`;
    try {
      const response = await axios.put(url+payload.id, payload);

      return response.data
    } catch (error) {

      const errorLogin = new Error(
        error.message || 'Failed to authenticate. Check your login data.'
      )
      throw errorLogin
    }
  },
  async tryTitleLists(context) {
    try {
      const menus = JSON.parse(localStorage.getItem('titleMenus'));
      if (menus) {
        context.commit('setTitlesMenu', {
          titles: menus
        });
      }
      return menus
    } catch (error) {
      const errorListMenu = new Error(
          error.message || 'Failed to authenticate. Check your login data.'
      )
      throw errorListMenu
    }
  },

}
