import { createStore, createLogger } from 'vuex';

import authModule from './modules/auth/index.js';
import customer from './modules/CR/customers/index.js';
import product from './modules/CR/Products/index.js';
import sysconf from './modules/sysconf/index.js';
import rolesPermission from './modules/RolesPermission/index.js';
import users from './modules/user/index.js';
import invoices from './modules/CR/Invoices/index.js';
import banks from './modules/CR/Banks/index.js';
import Title from './modules/RolesPermission/Titles/index.js';
import Menus from './modules/RolesPermission/Menus/index.js';

export default createStore({
  modules: {
    auth: authModule,
    customer: customer,
    product: product,
    sysconf: sysconf,
    rolesPermission: rolesPermission,
    Title: Title,
    Menus: Menus,
    users:users,
    invoices:invoices,
    banks: banks
  },
  plugins:[createLogger()]
})
