import axios from 'axios';
export default {
  async deleteBank({ commit }, bankId) {
    const url = `/banks/delete-bank/${bankId}`;
    try {
      await axios.delete(url);
      commit('DELETE_BANK', bankId);
    } catch (error) {
      console.log(error);
    }
  },
  async updateBank(context, payload) {
    try {
      const response = await axios.put(`/banks/update-bank/${payload.id}`, payload);
      context.commit('updateBank', response.data);
      localStorage.setItem('banks', JSON.stringify(context.state.banks));
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },
  async saveBank(context, payload) {
    const url = `/banks/generate-bank`;
    try {
      const response = await axios.post(url, payload);
      context.commit('ADD_BANK', response.data.data);
      localStorage.setItem('banks', JSON.stringify(context.state.banks));
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },
  async fetchBanks({ commit }, context) {
    await axios.get('/banks/get-banks')
      .then((response) => {
        commit('SET_BANKS', response.data.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  },
};
