import axios from 'axios'
export default {

  async listProduct(context) {
    const url = `/products/data-products`;
    try {
      const response = await axios.get(url);
      localStorage.setItem('productLists', JSON.stringify(response.data));
      context.commit('setProductLists', {
        productsLists: response.data,
      });
      return response
    } catch (error) {
      console.log(error)
    }
  },
  async tryListProduct(context) {
    try {
      const products = JSON.parse(localStorage.getItem('productLists'));
      if (products) {
        await    context.commit('setProductLists', {
          productsLists: products,
        });
      }
      return products
    } catch (error) {
      console.log(error)
    }
  },
  async searchProductsPos(context, payload) {
    try {
      const response = await axios.get(payload);
      context.commit('setProductLists', {
        productsLists: response.data
      });
      return response
    } catch (error) {
      console.log(error)

    }
  },
  async pagesProducts(context, payload) {
    try {
      const response = await axios.get(payload);

      localStorage.setItem('productLists', JSON.stringify(response.data));
   await context.commit('setProductLists', {
     productLists: response.data
      });
      return response
    } catch (error) {
      console.log(error)

    }
  }


}
