import axios from "axios";

export default {
  /***
   * Menú
   * @param context
   * @param payload
   * @returns {Promise<*>}
   */

  async searchMenuPermissions(context, payload) {
    const url = `/user/search-menus-permission`;
    try {
      const response = await axios.post(url,payload);
      localStorage.setItem('permissionUser', JSON.stringify(response.data.data));
    await  context.commit('setPermissionUser', {
        permission: response.data.data
      });
      return true;
    } catch (error) {

      const errorLogin = new Error(
        error.message || 'No Tiene permisos para esta parte del sistema'
      )
      throw errorLogin
    }
  },
  async trySearchMenuPermissions(context) {

    try {

      const permission = JSON.parse(localStorage.getItem('permissionUser'));
    await  context.commit('setPermissionUser', {
        permission: permission
      });
      return true;
    } catch (error) {

      const errorLogin = new Error(
        error.message || 'No Tiene permisos para esta parte del sistema'
      )
      throw errorLogin
    }
  },
  async userMenuPermissions(context,payload) {

    const url = `/user/user-menus-permission`;
    try {
      const response = await axios.post(url,payload);
      localStorage.setItem('permissionUser', JSON.stringify(response.data.data));
      await  context.commit('setPermissionUser', {
        permission: response.data.data
      });
      return true;
    } catch (error) {

      const errorLogin = new Error(
          error.message || 'No Tiene permisos para esta parte del sistema'
      )
      throw errorLogin
    }
  },
  async tryUserMenuPermissions(context) {

    try {
     let data = localStorage.getItem('permissionUser');
      await  context.commit('setPermissionUser', {
        permission: JSON.parse(data)
      });
      return true;
    } catch (error) {

      const errorLogin = new Error(
          error.message || 'No Tiene permisos para esta parte del sistema'
      )
      throw errorLogin
    }
  },
  async storeRole(context, payload) {
    const url = `/roles-permisos/store-role`;
    try {
      const response = await axios.post(url, payload);


      return response.data
    } catch (error) {

      const errorLogin = new Error(
        error.message || 'Failed to authenticate. Check your login data.'
      )
      throw errorLogin
    }
  },
  async updateRole(context, payload) {
    const url = `/roles-permisos/update-role/`+payload.id;
    try {
      const response = await axios.put(url, payload);


      return response.data
    } catch (error) {

      const errorLogin = new Error(
        error.message || 'Failed to authenticate. Check your login data.'
      )
      throw errorLogin
    }
  },

  async userLists(context) {
    const url = `/user/data-users`;
    try {
      const response = await axios.get(url);

      localStorage.setItem('data_users', JSON.stringify(response.data));
      context.commit('setDataUser', {
        users: response.data
      });
      return response.data
    } catch (error) {

      const errorLogin = new Error(
        error.message || 'Failed to authenticate. Check your login data.'
      )
      throw errorLogin
    }
  },
  async tryUsersLists(context) {
    try {
     // console.log('Se activo')
      const users = JSON.parse(localStorage.getItem('data_users'));
      if (users) {
        context.commit('setDataUser', {
          users: users
        });
      }
      return users
    } catch (error) {
      const errorListMenu = new Error(
        error.message || 'Failed to authenticate. Check your login data.'
      )
      throw errorListMenu
    }
  },
  async roleLists(context) {
    const url = `/roles-permisos/data-roles`;
    try {
      const response = await axios.get(url);

      localStorage.setItem('data_roles', JSON.stringify(response.data));
      context.commit('setDataRole', {
        roles: response.data
      });
      return response.data
    } catch (error) {

      const errorLogin = new Error(
          error.message || 'Failed to authenticate. Check your login data.'
      )
      throw errorLogin
    }
  },
  async tryRoleLists(context) {
    try {

      const menus = JSON.parse(localStorage.getItem('data_roles'));
      if (menus) {
        context.commit('setDataRole', {
          roles: menus
        });
      }
      return menus
    } catch (error) {
      const errorListMenu = new Error(
          error.message || 'Failed to authenticate. Check your login data.'
      )
      throw errorListMenu
    }
  },


}
