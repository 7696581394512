import axios from 'axios'
export default {
  async updateCustomer(context, payload) {
    try {
      const response = await axios.put(`/customers/update-customer/${payload.id}`, payload);
      context.commit('updateCustomer', response.data);
      localStorage.setItem('customers', JSON.stringify(context.state.customers));
      return response.data;
    } catch (error) {
      console.log(error);
      return error;
    }
  },
  async saveCustomer(context, payload) {
    const url = `/customers/generate-customer`;
    try {
      const response = await axios.post(url, payload);
      context.commit('ADD_CUSTOMER', response.data.data);
      localStorage.setItem('customers', JSON.stringify(context.state.customers));
      return response.data;
    } catch (error) {
      console.log(error);
      return error
    }
  },
  async getCustomers({ commit }, context) {
    await axios.get('/customers/get-customers')
      .then((response) => {
        commit('setCustomers', response.data.model.data);
      })
      .catch((error) => {
        console.error(error);
      });
  },
  /****************************** */
  async registerCustomerPos(context, payload) {
    const url = `/store-cliente-tracking`;
    try {
      return await axios.post(url, payload);

    } catch (error) {
      console.log(error)

    }
  },
  async consultCustomer(context, payload) {
    const url = `/edit-customer/`+payload;
    try {
      return await axios.get(url);

    } catch (error) {
      console.log(error)

    }
  },
  async listCustomer(context) {
    const url = `/customers/get-customers`;
    try {
      const response = await axios.get(url);
      localStorage.setItem('customerLists', JSON.stringify(response.data));
      context.commit('setCustomerLists', {
        customersLists: response.data,
      });
      return response
    } catch (error) {
      console.log(error)
    }
  },
  async tryListCustomer(context) {
    try {
      const customers = JSON.parse(localStorage.getItem('customerLists'));
      if (customers) {
        await    context.commit('setCustomerLists', {
          customersLists: customers,
        });
      }
      return customers
    } catch (error) {
      console.log(error)
    }
  },
  async searchCustomers(context, payload) {
    try {
      const response = await axios.get(payload);
      context.commit('setCustomerLists', {
        customersLists: response.data
      });
      return response
    } catch (error) {
      console.log(error)

    }
  },
  async pagesCustomers(context, payload) {
    try {
      const response = await axios.get(payload);

      localStorage.setItem('customerLists', JSON.stringify(response.data));
   await context.commit('setCustomerLists', {
        customersLists: response.data
      });
      return response
    } catch (error) {
      console.log(error)

    }
  }


}
