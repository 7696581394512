import mutations from './mutations.js'
import actions from './actions.js'
import getters from './getters.js'

export default {
  state() {
    return {
      menu: null,
      token: null,
      tokenExpiration: null,
      tokenRefresh: null,
      didAutoLogout: false,
      loggedIn: false,
      messageLogin:null
    };
  },
  mutations,
  actions,
  getters
}