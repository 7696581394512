export default {
  setTitlesMenu(state, payload) {

    state.titleMenus = payload.titles;
  },
  setDataMenu(state, payload) {

    state.dataMenus = payload.menus;
  },
  setDataRole(state, payload) {

    state.dataRoles = payload.roles;
  },
  setEditMenu(state, payload) {

    state.editMenu = payload.edit_menu;
  },
  setDataUser(state, payload) {

    state.dataUsers = payload.users;
  },
  setPermissionUser(state, payload) {

    state.permissionUser = payload.permission;
  }
}