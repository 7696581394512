import axios from "axios";

export default {
  /***
   * Menú
   * @param context
   * @param payload
   * @returns {Promise<*>}
   */
  async menuLists(context) {
    const url = `/roles-permisos/data-menus`;
    try {
      const response = await axios.get(url);

      localStorage.setItem('data_menus', JSON.stringify(response.data));
      context.commit('setDataMenu', {
        menus: response.data
      });
      return response.data
    } catch (error) {

      const errorLogin = new Error(
        error.message || 'Failed to authenticate. Check your login data.'
      )
      throw errorLogin
    }
  },
  async storeMenu(context, payload) {
    const url = `/roles-permisos/store-menu`;
    try {
      const response = await axios.post(url, payload);
      await store.dispatch('menuUser')
      await store.dispatch('tryMenuUser')
      return response.data

    } catch (error) {

      const errorLogin = new Error(
        error.message || 'Failed to authenticate. Check your login data.'
      )
      throw errorLogin
    }
  },
  async updateMenu(context, payload) {
    const url = `/roles-permisos/update-menu/`+payload.id;
    try {
      const response = await axios.put(url, payload);


      return response.data
    } catch (error) {

      const errorLogin = new Error(
        error.message || 'Failed to authenticate. Check your login data.'
      )
      throw errorLogin
    }
  },
  async editDataMenu(context, payload) {

    try {

      localStorage.setItem('edit_menu', (payload));
      context.commit('setEditMenu', {
        edit_menu: payload
      });

      return response.data
    } catch (error) {

      const errorLogin = new Error(
        error.message || 'Failed to authenticate. Check your login data.'
      )
      throw errorLogin
    }
  },
  async tryEditMenu(context) {

    try {

      let data = localStorage.getItem('edit_menu');
      context.commit('setEditMenu', {
        menu: JSON.parse(data)
      });

      return data
    } catch (error) {

      const errorLogin = new Error(
        error.message || 'Failed to authenticate. Check your login data.'
      )
      throw errorLogin
    }
  },

  async tryMenuLists(context) {
    try {
  //    console.log('Se activo')
      const menus = JSON.parse(localStorage.getItem('data_menus'));
      if (menus) {
        context.commit('setDataMenu', {
          menus: menus
        });
      }
      return menus
    } catch (error) {
      const errorListMenu = new Error(
        error.message || 'Failed to authenticate. Check your login data.'
      )
      throw errorListMenu
    }
  },



}
