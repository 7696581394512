import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export default {
  state() {
    return {
      banks: [], // Estado inicial de la lista de bancos
    };
  },
  mutations,
  actions,
  getters
}


