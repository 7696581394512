import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Select2 from 'vue3-select2-component'
import DataTable from 'datatables.net-vue3'
import Select from 'datatables.net-select'
import DataTablesLib from 'datatables.net'
import MazBtn from 'maz-ui/components/MazBtn'
import MazInput from 'maz-ui/components/MazInput'
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput'
import Editor from 'datatables.net-editor' ;

axios.defaults.baseURL = "https://friendlypos.net/api-web/v2"
//DataTable.use(Select);
createApp(App)
  .use(DataTable, Select,DataTablesLib,Editor)
  .use(VueAxios, axios)
  .use(store)
  .use(router)
  .use(Select2)
  .use(MazBtn)
  .use(MazInput)
  .use(MazPhoneNumberInput).mount('#app')


