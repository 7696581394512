export default {
  setToken(state, payload) {
    state.token = payload.token;
    state.tokenExpiration = payload.tokenExpiration;
    state.didAutoLogout = false;
    state.loggedIn = true
  },setMenu(state, payload) {
    state.menu = payload.menu
  },
  setAutoLogout(state) {
    state.didAutoLogout = true
    state.loggedIn = false
  },
  setMessageLogin(state, payload) {
    state.messageLogin = payload.message
  },
  setLogout(state) {
    state.token = null;
    state.didAutoLogout = true;

  }
};
