import axios from 'axios';


export default {
  async storeUser(context, payload) {
    const url = `/user/store-user`;

      const response = await axios.post(url, payload);


      return response.data;

  },
  async listUser(context, payload) {
    const url = `/data-de-usuarios`;
    try {
      const response = await axios.get(url, payload);

      localStorage.setItem('listUsers', JSON.stringify(response.data));


      return response.data
    } catch (error) {

      const errorLogin = new Error(
        error.message || 'Failed to authenticate. Check your login data.'
      )
      throw errorLogin
    }
  },
  async tryListUser(context) {

    try {

    const data =  localStorage.getItem('listUsers');
      context.commit('setListUsers', {
        listsUsers: data
      });

      return data;
    } catch (error) {

      const errorLogin = new Error(
        error.message || 'Failed to authenticate. Check your login data.'
      )
      throw errorLogin
    }
  },

  async SessionUser(context) {
    const url = `/user/user-session`;

      const response = await axios.get(url);
console.log(response.data.message);
      localStorage.setItem('userSession', JSON.stringify(response.data));
      context.commit('setUser', {
        user: response.data
      });
      context.commit('setMessageLogin', {
        message: response.data.message
      });
      return response.data

  },
  async trySessionUser(context) {

    try {
      const data = JSON.parse(localStorage.getItem('userSession'));
      context.commit('setUser', {
        user: data
      });
      return data
    } catch (error) {

      const errorLogin = new Error(
        error.message || 'Failed to authenticate. Check your login data.'
      )
      throw errorLogin
    }
  },
}
