export default {
  setCustomerLists(state,playload){
  
    state.customerLists = playload.customersLists;

  },
  setCustomers(state,customers){
  
    state.customers = customers;

  },
  ADD_CUSTOMER(state, customer) {
    state.customers.push(customer);
  },

  updateCustomer(state, updatedCustomer) {
    const index = state.customers.findIndex(customer => customer.id === updatedCustomer.id);
    if (index !== -1) {
      state.customers.splice(index, 1, updatedCustomer);
    }
  },
};
