export default {
  SET_BANKS(state, banks) {
    state.banks = banks;
  },
  ADD_BANK(state, bank) {
    state.banks.push(bank);
  },
  updateBank(state, updatedBank) {
    const index = state.banks.findIndex(bank => bank.id === updatedBank.id);
    if (index !== -1) {
      state.banks.splice(index, 1, updatedBank);
    }
  },
  DELETE_BANK(state, bankId) {
    state.banks = state.banks.filter(bank => bank.id !== bankId);
  }
};
