import mutations from './mutations.js'
import actions from './actions.js'
import getters from './getters.js'

export default {
  state() {
    return {
      sysconf: null,
      sysconfInfo: null,
      titlesMenu: null,
      listsNeighborhood: null,
    };
  },
  mutations,
  actions,
  getters
}
