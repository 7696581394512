import { createRouter, createWebHistory } from 'vue-router'
import store from '../store/index'

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue'),
    meta: {
      requiresAuth: false
    },
  },
  {
    path: '/home',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/Layout/structureLayout.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/',
    name: 'Inicio',
    component: () => import(/* webpackChunkName: "about" */ '../views/Inicio.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/configuracion',
    name: 'settings',
    component: () => import(/* webpackChunkName: "about" */ '../views/General/settingsSoft.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/mis-datos',
    name: 'misDatos',
    component: () => import(/* webpackChunkName: "about" */ '../views/General/myData.vue'),
    meta: {
      requiresAuth: true,

    }
  },
  /**
   * Productos en Electrónica
   */
  {
    path: '/lista-de-productos',
    name: 'listProducts',
    component: () => import(/* webpackChunkName: "about" */ '../views/Products/Index.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/nuevo-servicios',
    name: 'NewServices',
    component: () => import(/* webpackChunkName: "about" */ '../views/Products/NewServices.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/producto/:id/editar',
    name: 'editServices',
    component: () => import(/* webpackChunkName: "about" */ '../views/Products/EditServices.vue'),
    meta: {
      requiresAuth: true,

    }
  },
  /**
   * Fin de Productos
   */

  /**
   * Facturación Electrónica
   */
  {
    path: '/lista-de-documentos-electronicos',
    name: 'ListaDocumentosElectronicos',

    component: () => import(/* webpackChunkName: "about" */ '../views/Invoices/ListsDocuments.vue'),

    meta: {
      requiresAuth: true,
    }
  },
  /****
   * Facturas Pagadas
   */
  {
    path: '/lista-de-facturas-pagadas',
    name: 'ListaFacturasPagadas',

    component: () => import(/* webpackChunkName: "about" */ '../views/Invoices/ListsInvoicePaid.vue'),

    meta: {
      requiresAuth: true,
    }
  },
  /****
   * Facturas Canceladas
   */
  {
    path: '/lista-de-facturas-canceladas',
    name: 'ListaFacturasCanceladas',

    component: () => import(/* webpackChunkName: "about" */ '../views/Invoices/ListsInvoiceCancelled.vue'),

    meta: {
      requiresAuth: true,
    }
  },
  /****
   * Notas de Crédito
   */
  {
    path: '/lista-de-notas-creditos',
    name: 'ListaNotasCreditos',

    component: () => import(/* webpackChunkName: "about" */ '../views/Invoices/ListsCreditNotes.vue'),

    meta: {
      requiresAuth: true,
    }
  },
  /****
   * Notas de Débito
   */
  {
    path: '/lista-de-notas-debitos',
    name: 'ListaNotasdebitos',

    component: () => import(/* webpackChunkName: "about" */ '../views/Invoices/ListsDebitNotes.vue'),

    meta: {
      requiresAuth: true,
    }
  },
  /****
   * Lista de Compras
   */
  {
    path: '/lista-de-compras',
    name: 'ListaCompras',

    component: () => import(/* webpackChunkName: "about" */ '../views/Buys/ListsBuys.vue'),

    meta: {
      requiresAuth: true,
    }
  },
  /****
   * Lista de Ventas
   */
  {
    path: '/lista-de-ventas',
    name: 'ListaVentas',

    component: () => import(/* webpackChunkName: "about" */ '../views/Sales/ListsSales.vue'),

    meta: {
      requiresAuth: true,
    }
  },
  /****
   * Lista de Cotizaciones
   */
  {
    path: '/lista-de-cotizaciones',
    name: 'ListaCotizaciones',

    component: () => import(/* webpackChunkName: "about" */ '../views/Prices/ListsPrices.vue'),

    meta: {
      requiresAuth: true,
    }
  },
  /****
   * Registro de cuentas
   */
  {
    path: '/registro-de-cuenta',
    name: 'RegistroCuentas',

    component: () => import(/* webpackChunkName: "about" */ '../views/General/CreateAccounts.vue'),

    meta: {
      requiresAuth: true,
    }
  },
  /****
   * Editar cuentas
   */
  {
    path: '/editar-cuenta',
    name: 'EditarCuentas',

    component: () => import(/* webpackChunkName: "about" */ '../views/General/EditAccounts.vue'),

    meta: {
      requiresAuth: true,
    }
  },
  /****
   * Cuentas Bancarias
   */
  {
    path: '/cuentas-bancarias',
    name: 'banckAccounts',

    component: () => import(/* webpackChunkName: "about" */ '../views/General/banckAccounts.vue'),

    meta: {
      requiresAuth: true,
    }
  },
  /****
   * Lista de Sucursales
   */
  {
    path: '/lista-de-sucursales',
    name: 'ListaSucursales',

    component: () => import(/* webpackChunkName: "about" */ '../views/General/listBranches.vue'),

    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/facturacion-electronica',
    name: 'FacturacionElectronicaCliente',

    component: () => import(/* webpackChunkName: "about" */ '../views/Invoices/invoicesElectronic.vue'),

    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/facturacion',
    name: 'FacturacionElectronica',

    component: () => import(/* webpackChunkName: "about" */ '../views/Invoices/invoicesElectronic.vue'),

    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/tiquete-electronico',
    name: 'tiquete_Electronico',

    component: () => import(/* webpackChunkName: "about" */ '../views/Invoices/ticketElectronic.vue'),

    meta: {
      requiresAuth: true,
    }

  },
  {
    path: '/compra-electronico',
    name: 'compraElectronico',

    component: () => import(/* webpackChunkName: "about" */ '../views/Invoices/buysElectronic.vue'),

    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/notas-electronicas',
    name: 'notesElectronico',

    component: () => import(/* webpackChunkName: "about" */ '../views/Invoices/notesElectronic.vue'),

    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/recepcion-documentos',
    name: 'RecepcionDoc',

    component: () => import(/* webpackChunkName: "about" */ '../views/Reception/ReceptionFileXml.vue'),

    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/lista-compras-anuladas',
    name: 'comprasAnuladas',

    component: () => import(/* webpackChunkName: "about" */ '../views/Reception/index.vue'),

    meta: {
      requiresAuth: true
    }
  },
  /***
   * Sección de clientes
   */
  {
    path: '/registro-cliente-factura-electronico',
    name: 'RegistroClientesFE',
    component: () => import(/* webpackChunkName: "about" */ '../views/Customers/RegisterFacturaElectronic.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/registro-cliente-tiquete-electronico',
    name: 'RegistroClientesTE',
    component: () => import(/* webpackChunkName: "about" */ '../views/Customers/RegisterTiqueteElectronic.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/lista-de-clientes',
    name: 'ListaDeClientes',
    component: () => import(/* webpackChunkName: "about" */ '../views/Customers/Index.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/cliente/:id/editar',
    name: 'edicion_cliente',
    component: () => import(/* webpackChunkName: "about" */ '../views/Customers/EditCustomer.vue'),
    meta: {
      requiresAuth: true
    }
  },

  /***
   * Rutas de los reportes
   */

  /***
   * Configuración
   * Aquí inicia los menus para la parte de configuración y generación de rutas generales como el menus
   */
  {
    path: '/permisos/:id/menus/:sysconf/usuarios',
    name: 'permisos-menus-usuarios',
    component: () => import('../views/General/RolesPermission/PermissionMenu.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/asignacion-menus-roles',
    name: 'assignacion-menus-role',
    component: () => import('../views/General/RolesPermission/GroupMenuRole.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/activar-menus-usuarios',
    name: 'activar-menus-usuarios',
    component: () => import('../views/General/RolesPermission/PermissionMenu.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/asignacion-rol-a-usuarios',
    name: 'permisos-rol-usuarios',
    component: () => import('../views/General/RolesPermission/PermissionRole.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/registro-de-titulo',
    name: 'register-title',
    component: () => import('../views/General/RolesPermission/Titles/create.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/lista-de-titulos',
    name: 'list-title',
    component: () => import('../views/General/RolesPermission/Titles/index.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/registro-de-menus',
    name: 'register-menu',
    component: () => import('../views/General/RolesPermission/Menu/create.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/lista-de-menus',
    name: 'lista-menu',
    component: () => import('../views/General/RolesPermission/Menu/index.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/menu/:id/editar',
    name: 'editMenu',
    component: () => import('../views/General/RolesPermission/Menu/edit.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/lista-de-usuarios',
    name: 'lista-usuarios',
    component: () => import('../views/General/RolesPermission/Users/index.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/registro-de-usuarios',
    name: 'registro-usuarios',
    component: () => import('../views/General/RolesPermission/Users/create.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/lista-de-titulo',
    name: 'lista-titulo',
    component: () => import('../views/General/RolesPermission/Users/create.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/registro-de-usuarios-admin',
    name: 'registro-usuarios-admin',
    component: () => import('../views/General/RolesPermission/Users/createAdmin.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/lista-de-roles',
    name: 'lista-roles',
    component: () => import('../views/General/RolesPermission/Roles/index.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/role/:id/editar',
    name: 'roleEdition',
    component: () => import('../views/General/RolesPermission/Roles/edit.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/registro-de-rol',
    name: 'registro-roles',
    component: () => import('../views/General/RolesPermission/Roles/create.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/registro-de-cuentas',
    name: 'registro-cuentas',
    component: () => import('../views/General/createAccount.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/cuenta/:id/editar',
    name: 'editarCuentas',
    component: () => import('../views/General/EditAccount.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/registro-gratis',
    name: 'registro-gratis',
    component: () => import('../views/General/createAccountFree.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/registro-basico',
    name: 'registro-basico',
    component: () => import('../views/General/createAccountBasic.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/registro-profesional',
    name: 'registro-profesional',
    component: () => import('../views/General/createAccountProfesional.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/registro-ilimitada',
    name: 'registro-ilimitado',
    component: () => import('../views/General/createAccountIlimitado.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/password-reset',
    name: 'resetPassword',
    component: () => import('../views/General/passwordReset.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/reporte',
    name: 'reporte',
    component: () => import('../views/Reports/Report.vue'),
    meta: {
      requiresAuth: true
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {

  store.dispatch('tryLogin')
  // console.log(" to ", to.matched.some(route => route.meta.requiresAuth), " from ", from, ' store ', store.state.auth.loggedIn)

  if (to.matched.some(route => route.meta.requiresAuth)) {
    //  console.log(" to ", to.matched.some(route => route.meta.requiresAuth), " from ", from, ' store ', store.state.auth.loggedIn)
    if (!store.state.auth.loggedIn) {
      next('/login')
    } else {
      next()
    }
  } else {

      next()

  }
})
export default router
