export default {
  LISTUSERS(state) {
    return state.listsUsers
  },
  getttersUserSession(state) {
    return state.userSession
  },
/*   token(state) {
    return state.token
  },
  tokenExpiration(state) {
    return state.tokenExpiration
  },
  tokenRefresh(state) {
    return state.tokenRefresh
  },
  isAuthenticated(state) {
    return !!state.token
  },
  didAutoLogout(state) {
    return state.didAutoLogout
  } */
}